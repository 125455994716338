.SocialCard {
  height: 100%;
  width: 100%;
  background-image: url('bg.jpg');
  padding: 32px;
}

.SocialCardInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
  background-color: white;
  border-radius: 5px;
}
